/*--------------------------------------------------------------------------*
 * ハンバーガーメニュー
 *--------------------------------------------------------------------------*/
$(function () {
    $('[data-nav-trigger]').on('click', function () {
        $(this).toggleClass('active');
        $('[data-nav]').toggleClass('visible');
        return false;
    });

    $(window).on('scroll', function(){
        if ($(window).scrollTop() > 100) {
            $('header').addClass('fixed');
        } else {
            $('header').removeClass('fixed');
        }
    });
});